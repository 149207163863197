var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"commonProblemReply"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"问题标题:","rules":[
        { required: true, message: '请输入问题标题', trigger: 'blur' },
      ],"prop":"feedbackTitle"}},[_c('v-input',{attrs:{"disabled":"","placeholder":"请输入问题标题"},model:{value:(_vm.form.feedbackTitle),callback:function ($$v) {_vm.$set(_vm.form, "feedbackTitle", $$v)},expression:"form.feedbackTitle"}})],1),_c('el-form-item',{attrs:{"label":"问题类型:","rules":[
        { required: true, message: '请选择问题类型', trigger: 'blur' },
      ],"prop":"feedbackType"}},[_c('v-select',{attrs:{"disabled":"","options":_vm.problemTypesOps},model:{value:(_vm.form.feedbackType),callback:function ($$v) {_vm.$set(_vm.form, "feedbackType", $$v)},expression:"form.feedbackType"}})],1),_c('el-form-item',{attrs:{"label":"问题内容:","rules":[
        { required: false, message: '请输入问题内容', trigger: 'blur' },
      ],"prop":"feedbackContent"}},[_c('div',{attrs:{"id":"editor"}},[_c('v-ueditor',{attrs:{"isDisable":"","uniqueId":"feedbackContent","maxLength":20000},model:{value:(_vm.form.feedbackContent),callback:function ($$v) {_vm.$set(_vm.form, "feedbackContent", $$v)},expression:"form.feedbackContent"}})],1)]),_c('el-form-item',{attrs:{"label":"平台显示:","rules":[
        { required: true, message: '请选择是否平台显示', trigger: 'blur' },
      ],"prop":"isPlatformShow"}},[_c('v-select',{attrs:{"options":_vm.isPlatformShowOps},model:{value:(_vm.form.isPlatformShow),callback:function ($$v) {_vm.$set(_vm.form, "isPlatformShow", $$v)},expression:"form.isPlatformShow"}})],1),_c('el-form-item',{attrs:{"label":"是否置顶:","rules":[
        { required: true, message: '请选择是否置顶', trigger: 'blur' },
      ],"prop":"isRecommend"}},[_c('v-select',{attrs:{"options":_vm.isRecommendOps},model:{value:(_vm.form.isRecommend),callback:function ($$v) {_vm.$set(_vm.form, "isRecommend", $$v)},expression:"form.isRecommend"}})],1),_c('el-form-item',{attrs:{"label":"回复内容:","rules":[
        { required: true, message: '请输入回复内容', trigger: 'blur' },
      ],"prop":"optContent"}},[_c('div',[_c('v-ueditor',{attrs:{"uniqueId":"optContent","maxLength":20000},model:{value:(_vm.form.optContent),callback:function ($$v) {_vm.$set(_vm.form, "optContent", $$v)},expression:"form.optContent"}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }